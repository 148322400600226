import React from "react";
import { Carousel } from "react-responsive-carousel";
import tutorial1 from '../img/tutorial-1.svg'
import tutorial2 from '../img/tutorial-2.svg'

export default () => (
  <Carousel autoPlay showThumbs={false} showStatus={false} infiniteLoop={true}>
    <div className="tutorial__slide">
      <img src={tutorial1} loading="lazy" />
      <div className="my-4 uppercase text-red text-sm tracking-widest">
        swipe left & right to navigate quilt
      </div>
      <div className="leading-tight">Click on a block to read the story</div>
    </div>
    <div className="tutorial__slide">
      <img src={tutorial2} loading="lazy" />
      <div className="my-4 uppercase text-red text-sm tracking-widest">
        Pinch & zoom to navigate quilt
      </div>
      <div className="leading-tight">Click on a block to read the story</div>
    </div>
  </Carousel>
);
