import React, { useRef, useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import usStates from '../data/USStates'
import { PanZoom } from 'react-easy-panzoom'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import searchIcon from '../img/search.svg'
import Tutorial from '../components/Tutorial'
import { GatsbyImage } from "gatsby-plugin-image"

const states = Object.values(usStates)

const getRowAndColumn = function(row, column) {
  const rawcolumnnumber = column.charCodeAt(0)
  let rownumber
  let columnnumber
  let overallNumber
  if (column.length > 1) {
    columnnumber = rawcolumnnumber - 64 + 26
  } else {
    columnnumber = rawcolumnnumber - 64
  }
  rownumber = parseInt(row, 10)
  overallNumber = (rownumber - 1) * 29 + columnnumber
  return { r: rownumber, c: columnnumber, o: overallNumber }
}

const compare = function(a, b) {
  if (a.node.location.o < b.node.location.o) {
    return -1
  }
  if (a.node.location.o > b.node.location.o) {
    return 1
  }
  return 0
}

const EdgeArrow = ({ pos, click }) => (
  <div className={`toolbar__tool toolbar__tool--${pos}`} onClick={click}>
    <span className="uppercase text-xs">{pos}</span>
    <span className="toolbar__arrow text-red block">
      <svg className="stroke-current w-10 h-10" viewBox="0 0 45 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2 3L22.5 17L43 3" stroke="#F15060" strokeWidth="5"/>
      </svg>
    </span>
  </div>
)

const MicroBlock = ({ quiltBlock, searchHighlight }) => (
  <Link
    to={quiltBlock.title === "Empty" ? `/not-forgotten` : `/quilt/${quiltBlock.slug}`}
    state={{ modal: true }}
    className={`micro__block ${searchHighlight ? 'opacity-100' : 'opacity-10' }`}
  >
    {quiltBlock.quiltBlockFields.quiltImage && (
      <GatsbyImage
        className="absolute w-full h-full"
        loading="lazy"
        alt={quiltBlock.quiltBlockFields.quiltImage.altText}
        image={quiltBlock.quiltBlockFields.quiltImage.gatsbyImage}
      />
    )}
  </Link>
)

const MicroSite = ({ data }) => {
  const ref = useRef(null)
  const [jumpTo, setJumpTo] = useState(false)
  const [searchOpen, setSearchOpen] = useState(false)
  const [filtersOpen, setFiltersOpen] = useState(false)
  const [tutorial, setTutorial] = useState(true)
  const [searchTerm, setSearchTerm] = useState('')
  const [searchCategory, setSearchCategory] = useState('')
  const [searchLocation, setSearchLocation] = useState('')
  const [searchTime, setSearchTime] = useState('')
  const [searchGender, setSearchGender] = useState('')
  const [searchResults, setSearchResults] = useState([])
  const isMounted = useRef(false);

  const quilt = data.allWpQuiltBlock.edges
  quilt.map(block => {
    block.node.location = getRowAndColumn(
      block.node.quiltBlockFields.locationRow,
      block.node.quiltBlockFields.locationColumn
    )
  })
  quilt.sort(compare)

  // useEffect(() => {
  //   if (isMounted.current) {
  //     center()
  //   } else {
  //     isMounted.current = true;
  //   }
  // },[
  //   searchResults
  // ])

  function leftEdge() {
    ref.current.moveByRatio(3000, 0)
  }
  function rightEdge() {
    ref.current.moveByRatio(-3000, 0)
  }
  function topEdge() {
    ref.current.moveByRatio(0, 3000)
  }
  function bottomEdge() {
    ref.current.moveByRatio(0, -3000)
  }
  function center() {
    ref.current.autoCenter()
  }

  useEffect(() => {
    ref.current.zoomIn(9999)
    ref.current.moveByRatio(0, 0)
    ref.current.moveByRatio(0, 0)

    document.addEventListener('touchmove', function (event) {
      if (event.scale !== 1) { event.preventDefault(); }
    }, false);
  }, [])

  const clearAll = () => {
    const filterFields = document.querySelectorAll('.filter-field')
    filterFields.forEach(field => (field.value = 'All'))
    setSearchTerm('')
    setSearchLocation('')
    setSearchCategory('')
    setSearchTime('')
    setSearchGender('')
  }

  const handleSearch = event => {
    setSearchTerm(event.target.value)
  }

  const handleLocation = event => {
    setSearchLocation(event.target.value)
  }

  const handleCategory = event => {
    setSearchCategory(event.target.value)
  }

  const handleTime = event => {
    setSearchTime(event.target.value)
  }

  const handleGender = event => {
    setSearchGender(event.target.value)
  }

  useEffect(() => {
    let results = quilt
    if (searchTerm !== '') {
      results = quilt.filter(
        quiltBlock => {
          const blockTags = quiltBlock.node.tags ? quiltBlock.node.tags.nodes.map(tag => tag.name) : ''
          const blockTagString = quiltBlock.node.tags ? blockTags.join(' ') : ''
          const sanitizedName = quiltBlock.node.title.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")
          const sanitizedDonor = quiltBlock.node.quiltBlockFields?.donor ? quiltBlock.node.quiltBlockFields.donor.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") : ""
          return (
            sanitizedName.includes(searchTerm.toLowerCase()) ||
            sanitizedDonor.includes(searchTerm.toLowerCase()) ||
            blockTagString
              .toLowerCase()
              .includes(searchTerm.toLowerCase())
          )
        }
      )
    } else {
      results = quilt
        .filter(quiltBlock =>
          searchCategory && searchCategory !== 'All'
            ? (quiltBlock.node.categories.nodes.length && quiltBlock.node.categories.nodes[0].name === searchCategory)
            : quiltBlock.node
        )
        .filter(quiltBlock =>
          searchTime && searchTime !== 'All'
            ? quiltBlock.node.quiltBlockFields.century === searchTime
            : quiltBlock.node
        )
        .filter(quiltBlock =>
          searchLocation && searchLocation !== 'All'
            ? quiltBlock.node.quiltBlockFields.state === searchLocation
            : quiltBlock.node
        )
        .filter(quiltBlock =>
          searchGender && searchGender !== 'All'
            ? quiltBlock.node.quiltBlockFields.gender === searchGender
            : quiltBlock.node
        )
    }
    let slugs = results.map(x => x.node.slug);
    setSearchResults(slugs)
  }, [
    searchTerm,
    searchCategory,
    searchTime,
    searchLocation,
    searchGender,
  ])

  const toggle = () => {
    setSearchOpen(!searchOpen)
    const searchinput = document.querySelector('#search')
    searchinput.focus()
  }

  return (
    <div className="micro__container">
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
      </Helmet>
      <div className="toolbar__container">
        <div className="toolbar">
          <div>
            <h1 className="font-caps uppercase">
              The Legacy<br></br>Quilt Project
            </h1>
          </div>
          <div className=''>
            <button
              className={`uppercase tracking-widest flex items-center ${filtersOpen ? 'text-red' : 'text-black'}`}
              onClick={() => {setFiltersOpen(!filtersOpen); setJumpTo(false)}}
            >
              Search
              <span className="text-red">
                <svg
                  className="fill-current h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </span>
            </button>
            <div
              className={`absolute top-full w-screen left-0 bg-white p-4 shadow-lg z-50 ${
                filtersOpen ? 'block' : 'hidden'
              }`}
            >
              <div className={`${filtersOpen ? 'block' : 'hidden'} p-4 pb-8 bg-transparent h-auto`}>
                <div className="flex flex-wrap justify-between items-center">
                  <div>
                    <div className="inline-block relative w-full mb-1 md:mb-0 md:mr-2 md:w-auto">
                      <select
                        onChange={handleCategory}
                        className="filter-field"
                        defaultValue="Category"
                      >
                        <option disabled>Category</option>
                        <option>All</option>
                        {data.allWpCategory.edges.map(cat => (
                          <option key={cat.node.slug}>{cat.node.name}</option>
                        ))}
                      </select>
                      <div className="pointer-events-none text-red absolute inset-y-0 right-0 flex items-center px-2">
                        <svg
                          className="fill-current h-4 w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                      </div>
                    </div>

                    <div className="inline-block relative w-full mb-1 md:mb-0 md:mr-2 md:w-auto">
                      <select
                        onChange={handleLocation}
                        className="filter-field"
                        defaultValue="Location"
                      >
                        <option disabled>Location</option>
                        <option>All</option>
                        {states.map(us => (
                          <option key={us} value={us}>
                            {us}
                          </option>
                        ))}
                      </select>
                      <div className="pointer-events-none text-red absolute inset-y-0 right-0 flex items-center px-2">
                        <svg
                          className="fill-current h-4 w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                      </div>
                    </div>

                    <div className="inline-block relative w-full mb-1 md:mb-0 md:mr-2 md:w-auto">
                      <select
                        onChange={handleTime}
                        className="filter-field"
                        defaultValue="Time Period"
                      >
                        <option disabled>Time Period</option>

                        <option>All</option>
                        <option>1619-1718</option>
                        <option>1719-1818</option>
                        <option>1819-1918</option>
                        <option>1919-Present</option>
                      </select>
                      <div className="pointer-events-none text-red absolute inset-y-0 right-0 flex items-center px-2">
                        <svg
                          className="fill-current h-4 w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                      </div>
                    </div>

                    <div className="inline-block relative w-full mb-1 md:mb-0 md:mr-2 md:w-auto">
                      <select
                        onChange={handleGender}
                        className="filter-field"
                        defaultValue="Pronouns"
                      >
                        <option disabled>Pronouns</option>

                        <option>All</option>
                        <option>She/Her</option>
                        <option>He/Him</option>
                        <option>They/Them</option>
                        <option>N/A</option>
                      </select>
                      <div className="pointer-events-none text-red absolute inset-y-0 right-0 flex items-center px-2">
                        <svg
                          className="fill-current h-4 w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                      </div>
                    </div>
                    <div className="inline-block cursor-pointer absolute md:relative bottom-0 right-0 p-2 md:p-0">
                      <span
                        className="text-gray-500 cursor-pointer ml-5 underline"
                        onClick={clearAll}
                      >
                        clear filters
                      </span>
                    </div>
                  </div>

                  <div className="flex flex-col w-full md:w-auto md:flex-row items-center">
                    <div className="search__container relative w-full mb-1 md:mb-0 md:w-auto">
                      <div onClick={toggle} className="ml-1 p-1 hidden md:block cursor-pointer hover:opacity-50">
                        <img
                          className="search__icon"
                          src={searchIcon}
                          alt="upload"
                          style={{ width: '18px', height: '18px' }}
                          loading="lazy"
                        />
                      </div>
                      <div
                        className={`search__bar p-2 bg-neutral md:absolute w-full mb-1 md:mb-0 md:w-auto ${
                          searchOpen ? 'search__bar--show' : ''
                        }`}
                      >
                        <input
                          className="uppercase bg-transparent text-gray-900"
                          id="search"
                          type="text"
                          placeholder="Search"
                          value={searchTerm}
                          onChange={handleSearch}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            className={`uppercase tracking-widest flex items-center ${jumpTo ? 'text-red' : 'text-black'}`}
            onClick={() => {setJumpTo(!jumpTo); setFiltersOpen(false)}}
          >
            Jump To
            <span className="text-red">
              <svg
                className="fill-current h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </span>
          </button>
          <div
            className={`toolbar__tools toolbar__tools--${
              jumpTo ? 'show' : 'hide'
            }`}
          >
            <EdgeArrow click={leftEdge} pos="left" />
            <EdgeArrow click={rightEdge} pos="right" />
            <EdgeArrow click={topEdge} pos="top" />
            <EdgeArrow click={bottomEdge} pos="bottom" />
            <button
              className={`toolbar__tool toolbar__tool--center`}
              onClick={center}
            >
              <span className="centerButton uppercase text-xs">Center</span>
            </button>
          </div>
        </div>
        <div className={`${tutorial ? '' : 'hidden'} tutorial__container`}>
          <div className="tutorial">
            <button
              className="absolute top-0 right-0 z-50"
              onClick={() => setTutorial(!tutorial)}
            >
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 9L23.1704 22.8699"
                  stroke="#1A1A1A"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                />
                <path
                  d="M23.1704 9L9 22.8699"
                  stroke="#1A1A1A"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                />
              </svg>
            </button>
            <Tutorial />
          </div>
        </div>
      </div>
      <PanZoom
        ref={ref}
        realPinch
        boundaryRatioVertical={0.82}
        boundaryRatioHorizontal={0.945}
        enableBoundingBox
        minZoom={0.065}
        maxZoom={1}
        autoCenterZoomLevel={1}
        style={{ height:'100%', touchAction: "none" }}
      >
        <div className="micro__quilt">
          {quilt.map(({ node: quiltBlock }, i) => {
            let inResults = false
            let resultsActive = false
            if (searchResults.length) {
              resultsActive = true
            }
            if (searchResults.includes(quiltBlock.slug)) {
              inResults = true
            }
            return (
              <MicroBlock key={i} quiltBlock={quiltBlock} searchHighlight={inResults} />
            )
          })}
        </div>
      </PanZoom>
    </div>
  )
}

export const query = graphql`
  {
    allWpQuiltBlock(filter: { quiltBlockFields: { legacy: { eq: true } } }) {
      edges {
        node {
          id
          title
          slug
          date(formatString: "MMMM DD, YYYY")
          categories {
            nodes {
              name
              slug
            }
          }
          tags {
            nodes {
              name
              slug
            }
          }
          quiltBlockFields {
            century
            date
            city
            state
            description
            descriptionLong
            donor
            gender
            locationColumn
            locationRow
            legacy
            quiltImage {
              gatsbyImage(layout: CONSTRAINED, width: 100, height: 100)
              altText
            }
          }
        }
      }
      totalCount
    }
    allWpCategory {
      edges {
        node {
          databaseId
          name
        }
      }
    }
  }
`

export default MicroSite
